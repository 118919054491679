@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@200;300;400;500;600;700;800;900&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;

}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/*Track*/
::-webkit-scrollbar-track{
  background: #E0E0E0;
}

/*Handle*/
::-webkit-scrollbar-thumb{
  background: #888;
}

/*Handle on hover*/
::-webkit-scrollbar-thumb:hover{
  background: #555;
}
.pro-sidebar {
  transition: width 0.3s;
}



.fc-list-event {
  border-radius: 4px; /* Optional: Add border-radius if needed */
  margin: 5px 0; /* Optional: Adjust margin for spacing */
}

/* calendarStyles.css */
.fc-list-event:hover td {
  background-color: #0eead8 !important; /* Change to your desired hover background color and use !important */
}


/* third-party libraries CSS */
.tns-controls {
  @apply absolute left-1/2 bottom-14 z-30 flex -translate-x-1/2 space-x-5 lg:left-[70px] lg:translate-x-0;
}

.tns-controls button {
  @apply flex h-10 w-10 items-center justify-center rounded-full bg-primary bg-opacity-10 text-primary hover:bg-opacity-100 hover:text-white dark:bg-white dark:bg-opacity-10 dark:text-white dark:hover:bg-primary dark:hover:bg-opacity-100 dark:hover:text-white;
}

@layer base {
  *, ::before, ::after {
    @apply border-stroke;
  }
  
  body {
    @apply font-body;
  }
}

@layer components {
  html {
    @apply scroll-smooth;
  }

  .header {
    @apply py-5;
  }

  .sticky {
    @apply !fixed !z-[9999] !bg-white !bg-opacity-80 !py-2 !transition dark:!bg-dark;
    backdrop-filter: blur(5px);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  }

  .navbar > li > a {
    @apply lg:py-7;
  }

  .sticky .navbar > li > a {
    @apply lg:py-5;
  }

  .menu-scroll.active {
    @apply text-primary;
  }

  .menu-wrapper {
    @apply hidden;
  }

  .menu-wrapper.show {
    @apply flex;
  }

  .title {
    background: linear-gradient(
      180deg,
      rgba(74, 108, 247, 0.4) 0%,
      rgba(74, 108, 247, 0) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.2;
    @apply absolute left-1/2 top-0 -translate-x-1/2 text-[40px] font-extrabold !leading-none sm:text-[60px] lg:text-[95px];
  }

  .dark .title {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  #supportCheckbox:checked ~ div span {
    @apply opacity-100;
  }

  .submenu-item a.active {
    @apply text-primary dark:text-white;
  }
  .submenu-item a.active span svg {
    @apply rotate-180;
  }

  .grid-sizer {
    @apply w-full md:w-1/2 lg:w-1/3;
  }

  .portfolio-btn-wrapper button.active {
    @apply underline;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}